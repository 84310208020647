import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Slider() {
  return (
    <section className='slider-bg'>
    <Container fluid className='p-l-r-50 no-p'>
       
    <Carousel fade>
    <Carousel.Item  >
      <a href='https://www.dealshare.in/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/dealshare-bg-min.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
         <img className="w-100 forMob img-fluid"
          src="images/dealshare-bg-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
        <Carousel.Caption className='rit-cap ctm-caption'>
            <div className='slider-data'>
                
                <div className='banner-info'>
                  <img src="images/l-2-min.png" width="155" height="155" className='img-fluid' alt='demo'/>
                    <h3>Dealshare makes <br/>grocery shopping a breeze</h3>
                    <p>DealShare is a social ecommerce startup in India, targeting the new 500Mn users that have recently come online</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={6000} loop='true'>
      <a href='https://biryanibykilo.com/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/biryani-bg-min.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
          <img className="w-100 forMob img-fluid"
          src="images/biryani-bg-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
        <Carousel.Caption className='rit-cap ctm-caption'>
            <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-1-min.png" width="195" height="134" className='img-fluid' alt='demo'/>
                    <h3>Biryani by kilo</h3>
                    <p>Biryani By Kilo brings the Royal Biryani to the masses, prepared in the traditional Dum style. Prepared by our khansamas in handis which are hand made with natural clay specially customised for BBK</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={6000} loop='true'>
      <a href='https://www.airmeet.com/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/airmeet-bg-min.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
          <img className="w-100 forMob img-fluid"
          src="images/airmeet-bg-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
        <Carousel.Caption className='rit-cap ctm-caption'>
            <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-6-min.png" width="94" height="89" className='img-fluid' alt='demo'/>
                    <h3>Make your events <br/>standout</h3>
                    <p>Airmeet is a platform for virtual summits, meetups & workshops with a social lounge to deliver a rich networking experience.</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <a href='https://www.yulu.bike/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/yulu-bikes-min.jpg"
          alt="Second slide" rel="preload" width="1703" height="717"
        />
         <img className="w-100 forMob img-fluid"
          src="images/yulu-bikes-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
        <Carousel.Caption className='lft-cap ctm-caption'>
        <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-3-min.png" width="177" height="73" className='img-fluid' alt='demo'/>
                    <h3>PEDAL THE CHANGE</h3>
                    <p>Smart dockless bike sharing system for new India is here. Enjoy the freedom to move around. Show your love for the environment. #PedalTheChange</p>
                    
                </div>
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <a href='https://www.agnikul.in/#/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/agnikul-bg-min.jpg"
          alt="Third slide" rel="preload" width="1703" height="717"
        />
        <img className="w-100 forMob img-fluid"
          src="images/agnikul-bg-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
          </a>
        <Carousel.Caption className='rit-cap ctm-caption'>
        <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-4-min.png" width="109" height="142" className='img-fluid' alt='demo'/>
                    <h3>Agnikul Bringing Space <br/>within Everyone's reach</h3>
                    <p>Going to space shouldn't be the hardest part of utilizing, living in or working from space. So, why not design & build a product that makes the earth-to-space journey as simple, as quick and as affordable as possible?</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <a href='https://klubworks.com/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/klub-bg-min.jpg"
          alt="Third slide" rel="preload" width="1703" height="717"
        />
        <img className="w-100 forMob img-fluid"
          src="images/klub-bg-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
        <Carousel.Caption className='rit-cap ctm-caption'>
        <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-7-min.png" width="168" height="66" className='img-fluid' alt='demo'/>
                    <h3>Because your<br/> growth can't wait</h3>
                    <p>India's leading Revenue Based Financing platform with a vision to revolutionize growth capital. Join the Klub for fast, fair & flexible funding.</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <a href='https://pandorum.com/' target="_blank" rel="noreferrer">
        <img
          className="w-100 forDsk img-fluid"
          src="images/pendurom-min.jpg"
          alt="Third slide" rel="preload" width="1703" height="717"
        />
        <img className="w-100 forMob img-fluid"
          src="images/pendurom-min-mob.jpg"
          alt="First slide" rel="preload" width="1703" height="717"
        />
        </a>
         
        <Carousel.Caption className='rit-cap ctm-caption'>
        <div className='slider-data'>
                
                <div className='banner-info'>
                <img src="images/l-5-nu-min.png" className='img-fluid' width="172" height="112" alt='demo'/>
                    <h3>Tissue engineering</h3>
                    <p>Pandorum is a biotechnology company with focus on tissue engineering and regenerative medicine.</p>
                    
                </div>
                
            </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <Row>
      <Col>
        <div className="textSec">
        <div className='section-heading'>
            <h3 className=''>GrayCell Ventures provides <span>funding</span>, <span>strategic consulting</span>, and <span>technical expertise</span> to start-ups in Web.</h3>
        </div>
        </div>
        </Col>
    </Row>
    <div className='divider'>
        <hr />
    </div>
    </Container>
    </section>
  )
}

export default Slider