import React,{useState, useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import  Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import  axios  from 'axios';



function Contact() {
    const [showText, setShowtext]= useState(false);
    const [showError, setShowerror] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const form = useRef();
    // const {register,handleSubmit,reset,formState: { errors },} = useForm();
    const {handleSubmit,formState: { errors },} = useForm();
    const recaptchaRef = React.createRef();
    const API_PATH = 'https://graycellventures.com/wordpress/mail.php';

    const [userData, setUserdata] = useState({
        user_name:'',
        user_email:'',
        subject:'',
        message:'',
    })
    
const validate = (values) => {
    const errors = {};
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const regexname = /^[a-zA-Z ]*$/;
    if (!values.user_name) {
      errors.user_name = "";
      errors.errorName = true;
    }
    if (!values.user_email) {
      errors.user_email = "";
      errors.emailerror = true;
    } else if (!regex.test(values.user_email)) {
      errors.user_email = "";
      errors.emailerror = false;
      errors.inval_email= true;
    }
    if (!values.message) {
        errors.message = "";
        errors.snglmsg = true;
      }
      if (!values.subject) {
        errors.subject = "";
        errors.errorSubject = true;
      }
    
    return errors;
  };
    const handleInput = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setUserdata({...userData, [name]: value})
    };


    function sendPhp(data){
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: data
          }).then((result) => {
           
            setTimeout(() =>{
                setShowtext(false);
            }, 4000);
            setUserdata({
                user_name:'',
                user_email:'',
                subject:'',
                message:'',
            });
           
      console.log(result);
  }, (error) => {
      console.log(error.text);
  });
    }

    
    const captchCheck = async () =>{
    
        const token = await recaptchaRef.current.getValue();
        const submitData = { ...userData };
        setFormErrors(validate(submitData));
        console.log(submitData);
        console.log(formErrors);
        let formObjLngth = Object.keys(formErrors).length;
        if(submitData.user_name==='' || submitData.user_email==='' || submitData.message===''){
            formObjLngth =+1
        }
        console.log(formObjLngth);
       
        if(token && formObjLngth === 0){
            setShowerror(false);
            sendPhp(submitData);
            setShowtext(true);
            
            window.grecaptcha.reset();
        }else if (token) {
            setShowerror(false);
          } else{
            setShowerror(true);
        }
    }

const tokenHeandler = async () =>{
    const mytoken = await recaptchaRef.current.getValue();
    setShowerror(false);
}


  return (
    <section className='mt-5 mb-2' id='Contact-Us'>
    <Container fluid className='p-l-r-50'>
    <div className='divider'>
                <hr />
            </div>
        <Row>
            <Col md={4}>
                <div className='lft-sec'>
                    <h2>SO LET'S JOIN HANDS AND MAKE FUTURE HAPPEN!</h2><br/>
                    <p>Looking to start a venture or looking for a mentor for your start-up, please get in touch with us here.</p>
                </div>
                <div className='contactInfo'>
                    <ul>
                        <li className='addLi'>GrayCell Ventures, SCO-15 (IIIrd Floor),<br/>Adjacent to Neelam Cinema, Sector-17E,<br/>Chandigarh - 160017, India.</li>
                        <li className='emailLi'><a href='mailto:info@graycellventures.com'>info@graycellventures.com</a></li>
                        <li className='phoneLi'><a href='tel:+91-7307242527'>+91-7307242527</a></li>
                        <li className='phoneLi'><a href='tel:+91-9023252526'>+91-9023252526</a></li>
                    </ul>
                </div>
                
                <div className='social-link contact-social'>
                    <a href="https://in.linkedin.com/in/mjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-linkedin"></i></a>
                    <a href="https://twitter.com/munishjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-twitter-x"></i></a>
                    <a href="https://www.facebook.com/GrayCellVentures" target="_blank" rel="noreferrer" ><i class="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/munishjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-instagram"></i></a>
                </div>
            </Col>
            <Col md={2}></Col>
            <Col md={6} className='rit-sec'>
                <h2>LEAVE US A NOTE</h2>
            {/* <Form ref={form} onSubmit={handleSubmit(captchCheck)} className='mt-5'> */}
            <Form ref={form} onSubmit={handleSubmit(captchCheck)} className='mt-5'>
                <Row>
                <Col md={6}>
                <Form.Group className="mb-3" >
                <Form.Control name="user_name" value={userData.user_name} onChange={handleInput} className='inpuStyle' type="text" placeholder="Name*"/>
                {formErrors.errorName && <span style={{color:'red'}}>Please fill out this field.</span>}
            </Form.Group>
            <p style={{color:'red'}}>{formErrors.user_name}</p>
                </Col>
                <Col md={6}>
                <Form.Group className="mb-3" >
                <Form.Control className='inpuStyle' name="user_email" value={userData.user_email} onChange={handleInput} type="text" placeholder="Email*" />
                {formErrors.emailerror && <span style={{color:'red'}}>Please fill out this field.</span>}
                {formErrors.inval_email && <span style={{color:'red'}}> Invalid email address. Please enter a valid email address.</span>}
                </Form.Group>
           
                </Col>
                </Row>
                <br/>
                <Form.Group className="mb-3" >
                <Form.Control name="subject" value={userData.subject} onChange={handleInput} className='inpuStyle' type="text" placeholder="Subject*" />
                {formErrors.errorSubject && <span style={{color:'red'}}>Please fill out this field.</span>}
            </Form.Group>
            <Form.Group className="mb-3 mt-5" controlId="exampleForm.ControlTextarea1">
                <Form.Control name="message" value={userData.message} onChange={handleInput} as="textarea" rows={3} placeholder="Message*"  />
                {formErrors.snglmsg && <span style={{color:'red'}}>Please fill out this field.</span>}
            </Form.Group>
            <p style={{color:'red'}}>{formErrors.message}</p>
            <ReCAPTCHA onChange={tokenHeandler} ref={recaptchaRef} sitekey="6Lc2Af4hAAAAAMiENXuB2AG7X-_kcoSjlKeZ74wT"  />
            {showError && <span style={{color:'red'}}> Invalid CAPTCHA. Please verify and try again.</span>}
            
            <div className='form-submnit'>
            <Button type="submit" className="ctm-btn mt-2" >Submit</Button>
            </div>
            </Form>
            {
                showText && <Col className='bsgbox'><div className='msg'><h5>Thank you! Your message has been successfully received. We will get back to you as soon as possible.</h5></div></Col>
            }
            </Col>
        </Row>
    </Container>
    </section>
  )
}

export default Contact