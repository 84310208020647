import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function WhatWeOffer() {
  return (
    <section className='mt-2 mb-2' id='WhatWeOffer'>
    <Container fluid className='p-l-r-50'>
        <Row className='align-items-center'>
            <Col lg={3}>
                <div className='lft-sec mt-5 offer-text'>
                    <div className='hdanimation'></div>
                    <h2>What We Offer</h2>

                    <p className='text-justy'>Having worked with hundreds of startups during his decade long journey as an entrepreneur, Munish has had first-hand experience of what pains and pangs constraint a startup faces. He works with early stage ventures to define the business plan and technology requirements. He also acts as an independent consultant and evaluator and the graycell venture team can chip in whenever the venture needs development help or even a third eye perspective. He firmly believes that innovation and the right application is the key to success and loves to contribute to the tribe of pioneers that think differently and have the passion to turn their dreams to reality. He likes to work as a team with the new brigade of innovators.</p>
                    {/*<h6>So let's join hands and make future happen!</h6>
                    <h6>Looking to start a venture or looking for a mentor for your start-up, please get in touch with us <a href='/gct/graycell-ventures-react/#Contact'>here</a>.</h6>*/}
                </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={8}>
                <div className='right-sec'>
                    <Row>
                        <Col md={4}>
                            <div className='rit-sec-itembox wwobox'>
                                <img src='images/006-heptagon.png' width="64" height="64" alt="demo" />
                                
                                {/*<h5>Industry Experience</h5>*/}
                                <h5>First hand industry experience that ensures high quality mentoring.</h5>
                            </div>
                        </Col>
                        <Col md={4}>
                        <div className='rit-sec-itembox wwobox'>
                                <img src='images/005-star.png' width="64" height="64" alt="demo" />
                                 <h5>Large talent pool of technology evangelists</h5>
                            </div>
                        </Col>
                        <Col md={4}>
                        <div className='rit-sec-itembox wwobox'>
                                <img src='images/007-star-1.png' width="64" height="64" alt="demo" />
                               
                                <h5>Network of business and technology resources</h5>
                            </div>
                        </Col>
                        <Col md={4}>
                        <div className='rit-sec-itembox wwobox'>
                                <img src='images/006-perspective.png' width="64" height="64" alt="demo" />
                                
                                <h5>Focus on the team and the product </h5>
                            </div>
                        </Col>
                        <Col md={4}>
                        <div className='rit-sec-itembox wwobox'>
                                <img src='images/004-octahedron.png' width="64" height="64" alt="demo" />
                                
                                <h5>Tangible inputs for isolating and minimizing Risk.</h5>
                            </div>
                        </Col>
                        <Col md={4}>
                        <div className='rit-sec-itembox wwobox'>
                                <img src='images/005-abstract-shape-3.png' width="64" height="64" alt="demo" />
                                
                                <h5>Follow the KIS (Keep it Simple) methodology</h5>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>
    <div className='grd-2'></div>
    </section>
  )
}

export default WhatWeOffer