import Home from './Pages/Home'
import TopNav from './Component/TopNav'
import Footer from './Component/Footer'


function App() {
  return (
    <>
    <TopNav />
    <Home />
    <Footer />
   </>
  );
}

export default App;
