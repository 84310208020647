import React from 'react';
import TopBanner from '../Component/TopBanner';
import Slider from '../Component/Slider';
import WhatWeOffer from '../Component/WhatWeOffer';
import WhoWeAre from '../Component/WhoWeAre';
import MediaTwo from '../Component/MediaTwo';
import WhowrBanner from '../Component/WhowrBanner';
import Scrolltop from '../Component/Scrolltop';
import Contact from '../Component/Contact';
import ProtfolioSwiper from '../Component/ProtfolioSwiper';
import Performed from '../Component/Performed';

function Home() {

  return (
    <>
    <Scrolltop />
    <Slider />
    <WhatWeOffer />
    <Performed />
    <WhoWeAre />
    <ProtfolioSwiper/>
    <MediaTwo />
    <Contact/>
    </>
  )
}

export default Home