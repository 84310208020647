import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function TopNav() {
  return (
    <div className='topNav'>
    <Navbar collapseOnSelect expand="lg" className='ctm-nav'>
      <Container fluid className='ctm-menu p-l-r-50'>
        <Navbar.Brand href="/"><img src='images/logo-min.png' width="585" height="199" alt="GrayCell Ventures" className='img-fluid brandLogo'/></Navbar.Brand>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#responsive-navbar-nav"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
        <Navbar.Collapse id="responsive-navbar-nav" className='mrg-lr pb-3 pt-4'>
          <Nav className="ms-auto">
            <Nav.Link href="/#WhoWeAre">Who We Are<svg fill="none" viewBox="0 0 182.24 72.42" class="menu-item-circle"><path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path></svg></Nav.Link>
            <Nav.Link href="/#WhatWeOffer">What We Offer<svg fill="none" viewBox="0 0 182.24 72.42" class="menu-item-circle"><path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path></svg></Nav.Link>
            <Nav.Link href="/#Portfolio">Portfolio<svg fill="none" viewBox="0 0 182.24 72.42" class="menu-item-circle"><path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path></svg></Nav.Link>
            <Nav.Link href="/#Media">Media<svg fill="none" viewBox="0 0 182.24 72.42" class="menu-item-circle"><path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path></svg></Nav.Link>
            <Nav.Link href="/#Contact-Us">Contact Us<svg fill="none" viewBox="0 0 182.24 72.42" class="menu-item-circle"><path d="M90.41,54.27c-8.39.86-16.76,2-25.17,2.54-13.82.88-27.67,1.65-41.52,1.87-5.13.09-10.39-1.09-15.39-2.44C-1.93,53.46-1.48,43.65,3.09,37.4,8,30.66,14.55,25.83,21.49,21.58,36.89,12.14,53.72,6.35,71.41,3.07A196.63,196.63,0,0,1,111.31.32,135.77,135.77,0,0,1,160.5,10.58c6.69,2.74,13.35,5.86,18.34,11.41,2.79,3.1,4.65,6.75,2.39,10.87a13.45,13.45,0,0,1-4.49,4.48c-6.57,4.26-14,6.62-21.49,8.47-22.43,5.52-44.88,11-67.43,16C71.3,65.5,54.62,68.46,38,71.71a5.33,5.33,0,0,1-2.5-.11c6.85-1.37,13.71-2.69,20.55-4.12q14.14-2.94,28.26-6c9.74-2.11,19.52-4.07,29.19-6.46,16.35-4.05,32.68-8.21,48.91-12.72a63.58,63.58,0,0,0,14-6.25c5.46-3.1,5.82-8,1.63-12.61-4.88-5.38-11.23-8.5-17.7-11.22A137.91,137.91,0,0,0,107.72,1.66,184.85,184.85,0,0,0,64,6,140.25,140.25,0,0,0,27,19.92c-7.49,4.19-14.79,8.68-20.4,15.32A26.84,26.84,0,0,0,2.1,42.8C-.53,49.71,3.86,53.17,8.49,55c7.15,2.78,14.66,3,22.14,2.58,15.73-.81,31.45-1.89,47.18-2.88,4.19-.27,8.38-.64,12.58-1Z"></path></svg></Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
     <hr/>
     </div>
  )
}

export default TopNav