import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Linkedin,Twitter} from 'react-bootstrap-icons';

function WhoWeAre() {
    const [showImg, setShowimg] = useState(false);
  return (
    <section className='mt-5 pt-5' id='WhoWeAre'>
    <Container fluid className='p-l-r-50'>
       
        <Row className='align-items-center'>
            <Col lg={3} >
                <div className='wwr-lft-box'>
                <div className='lft-sec textCenter'>
                    <div className='img-bg' onMouseEnter={() => setShowimg(true)} onMouseLeave={() => setShowimg(false)}>
                    
                    
                    </div>
                    
                    <h2>MUNISH JAUHAR</h2>
                    <h5>Founder</h5>
                    <div className='social-link'>
                        <a href="https://in.linkedin.com/in/mjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-linkedin"></i></a>
                        <a href="https://twitter.com/munishjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-twitter-x"></i></a>
                    </div>
                </div>
                </div>
            </Col>
            {/*<Col lg={1}></Col>*/}
            <Col lg={8}>
                <div className='right-sec'>
                    <Row>
                        <Col md={12}>
                            <div className='rit-sec-itembox wwaBox'>
                                
                            
                                <p>As the CEO/Founder of GrayCell Technologies, Munish Jauhar has been living the tech dream. Post his Masters in Computer Applications from Panjab University, Chandigarh, India, an enriching stint with Quark Media House as Product Manager and a US-based start-up, Jauhar moved to the next level. To satisfy the entrepreneurial fire in his belly, he took the risk and set up GrayCell Technologies in 2004. Today, GrayCell Technologies has grown exponentially servicing an ever increasing list of Global 2000 clients. The team has swelled to a formidable force of highly skilled professionals.</p>
                                <p>Mr. Jauhar then launched GrayCell Ventures to help and mentor young budding entrepreneurs. His aim with GrayCell Ventures is not only to provide seed capital for start-ups in software, web, mobile and the cloud computing space but to act as mentor for the talent. An active angel investor he has invested in over 100 startups. In 2022 he was listed in India's top 100 Angel investors by Fortune India (ranked at number 26 on the list). He a founding member of Chandigarh Angel Network.</p>
                                <p>In 2011 for his achievements in the software arena Munish was awarded the prestigious Asia Pacific Entrepreneurship Award for Most Promising Entrepreneur.</p>
                                <p>Munish is the current vice chair of NASSCOM Chandigarh & Punjab Chapter. He is also the founding curator for the Chandigarh Hub of the World Economic Forum's Global Shapers Community. He is the ex-President of TiE - Chandigarh, a mentor to TEDx Chandigarh and ex-chair of Yi (Young Indians), Chandigarh chapter.</p>
                                <p>An avid collector of both modern and contemporary Indian art, his other interests include birding, long distance running, reading, fish keeping and music.</p>
                            </div>
                        </Col>
                        
                        
                    </Row>
                </div>
            </Col>
        </Row>
        <div className='divider'>
            <hr />
        </div>
    </Container>
    </section>
  )
}

export default WhoWeAre