import React,{useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode,Navigation,Autoplay } from 'swiper';
import "swiper/css/free-mode";

// Import Swiper styles
import 'swiper/css';

function ProtfolioSwiper() {

    const [postData, setPostData] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const regex = /(<([^>]+)>)/ig;
    let limit = 150;
    
    useEffect(() => {
        const featchData = async() =>{

            const res = await fetch(
              `https://graycellventures.com/wordpress/wp-json/wp/v2/port?category_slug=portfolio&page=1&per_page=${limit}`
            );
            const data = await res.json();
           /* const total = res.headers.get("x-total-count");
           setpageCount(Math.ceil(total / limit));*/
           const total = res.headers.get("x-wp-totalpages");
            setpageCount(total);
            setPostData(data);
          }
    
        
        featchData();
      },[limit])   


  return (
    <section className='sec-mrg' id="Portfolio">

            <Container fluid className='pt-3 p-l-r-50'>
                <div className='section-heading mrg-t2-b3'>
                    <div className='hdanimation mx-auto'></div>
                    <h2 className='text-center'>SOME OF THE COMPANIES WE INVESTED IN</h2>
                </div>

            <Swiper 
            freeMode={true}
            grabCursor={true}
            modules={[FreeMode,Navigation,Autoplay]}
            className="mySwiper"
            breakpoints={{
              0:{
                slidesPerView: 1,
                spaceBetween: 10,
            
              },
              600:{
                slidesPerView: 2,
                spaceBetween: 10,
            
              },
              768:{
                slidesPerView: 2,
                spaceBetween: 10,
            
              },
              992:{
                slidesPerView: 3,
                spaceBetween: 20,
            
              },
              1024:{
                slidesPerView: 4,
                spaceBetween: 30,
            
              }
            }}
            slidesPerView={4}
            spaceBetween={30}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            navigation={{
                nextEl: ".custom_next",
                prevEl: ".custom_prev"
              }}
            >
                 {  
                    postData.map((post,index) => (
                      
                 
                <SwiperSlide key={index}>
                <div className='item'>
                {
                               ( post.acf.portfolio_link === undefined && 
                               <>
                        <img src={post.fimg_url} width="400" height="313" rel="preload" className='img-fluid' alt={post.title.rendered}/>
                            <div className="overlay">
                                <div className="text">
                                    <h4>{post.title.rendered} </h4>
                                    <p>{post.content.rendered.replace(regex, '').substr(0,50) + '...'}</p>
                                    
                                </div>
                            </div>
                        </>
                         )}
                {
                               ( post.acf.portfolio_link === '#' && 
                               <>
                        <img src={post.fimg_url} width="400" height="313" rel="preload" className='img-fluid' alt={post.title.rendered}/>
                            <div className="overlay">
                                <div className="text">
                                    <h4>{post.title.rendered}  </h4>
                                    <p>{post.content.rendered.replace(regex, '').substr(0,50) + '...'}</p>
                                    
                                </div>
                            </div>
                        </>
                         )}
                          {
                                    ( post.acf.portfolio_link !== '#' && post.acf.portfolio_link !== undefined && 
                                      <a href={post.acf.portfolio_link} target="_blank" rel="noreferrer" >
                                      <img src={post.fimg_url} width="400" height="313" rel="preload" className='img-fluid' alt={post.title.rendered} />
                                      <div className="overlay">
                                          <div className="text">
                                              <h4>{post.title.rendered} </h4>
                                              <p>{post.content.rendered.replace(regex, '').substr(0,50) + '...'}</p>
                                              
                                          </div>
                                      </div>
                                    </a>    
                                    )}

                    </div>
                </SwiperSlide>
  ))}
                <div className='d-flex justify-content-center mt-5 ctm-nav-set'>
                
                    
                    <span className='custom_prev'><i class="bi-arrow-left"></i></span>
                    <span className='custom_next'><i class="bi-arrow-right"></i></span>
                </div>
            </Swiper>
            

                </Container>
        </section>
  )
}

export default ProtfolioSwiper