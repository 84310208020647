import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Performed() {
  return (
    <Container fluid className='p-l-r-50 mb-5'>
        <div className='divider'>
            <hr />
        </div>
        
        
    <Row className='mt-5'>
    <Col md={4} lg={5}>
    <div className='section-heading perform-sec'>
            <div className='hdanimation'></div>
            <h2 className=''>How we've performed over the years</h2>
        </div>
    </Col>
    <Col md={8} lg={7}>
        <div className='outerBox'>
            <div className='innreBox'>
                <div className='performedInfo'>
                    <div className='performediconBox'>
                        <img src='images/Asset2.png' alt="Startups Invested in" className="img-fluid"/>
                    </div>
                    <div className='performedtext'>
                        <h4><span>120+</span> </h4>
                        <h6>Startups Invested in</h6>
                    </div>
                    
                </div>
            </div>
            <div className='innreBox'>
                <div className='performedInfo'>
                    <div className='performediconBox'>
                        <img src='images/Asset3.png' alt="Startups Invested in" className="img-fluid"/>
                    </div>
                    <div className='performedtext'>
                        <h4><span>$50mn</span> </h4>
                        <h6>Raised by Portfolio</h6>
                    </div>
                    
                </div>
            </div>
            <div className='innreBox'>
                <div className='performedInfo'>
                    <div className='performediconBox'>
                        <img src='images/Asset4.png' alt="Startups Invested in" className="img-fluid"/>
                    </div>
                    <div className='performedtext'>
                        <h4><span>20</span> </h4>
                        <h6>Exits</h6>
                    </div>
                    
                </div>
            </div>
        </div>
        </Col>
    </Row>
   
    </Container>
  )
}

export default Performed