import React,{useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import {ArrowLeft} from 'react-bootstrap-icons';

function MediaTwo() {
    const [postData, setPostData] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const regex = /(<([^>]+)>)/ig;
    let limit = 8;

    
    const featchDataonClick = async(currentPage) =>{
        await axios.get(`https://graycellventures.com/wordpress/wp-json/wp/v2/posts?_embed&category_slug=news&page=${currentPage}&per_page=${limit}`).then((res)=>{
        const posts = (res.data);
        setPostData(posts);
       
      })
    }
    const handlePageClick = async (data) =>{
        let currentPage = data.selected + 1;
        const posts = await featchDataonClick(currentPage);

        
    }


    useEffect(() => {
        const featchData = async() =>{
       
           
           const res = await fetch(
             'https://graycellventures.com/wordpress/wp-json/wp/v2/posts?_embed&category_slug=news&page=1&per_page=8'
           );
           const data = await res.json();
         
          const total = res.headers.get("x-wp-totalpages");
           setpageCount(total);
           setPostData(data);
           console.log(data);
         }
        featchData();
      },[limit])

    return (
        <section id='Media'>
            <Container fluid className='p-l-r-50'>
                <div className='divider'>
                    <hr />
                </div>
                <Row>
                    <Col md={12} className='section-heading mrg-t2-b3'>
                        <div className='hdanimation mx-auto'></div>
                        <h2 className='text-center'>SOME OF OUR PORTFOLIO COMPANIES FEATURED IN THE MEDIA</h2>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row className='media-card-box'>
                {
                    postData.map((post,index) => (
                    <Col key={index} md={6} lg={3} className='media-item mb-5'>
                        <div className='media-card'>
                        {( post.External_Link[0] &&
                        <a href={post.External_Link[0]} target="_blank" rel="noreferrer">
                            <Card style={{ width: '100%', padding: '15px'}}>
                                <div className='mdaImgBox'>
                                    {
                                    ( post.fimg_url && 
                                        <Card.Img variant="top" rel="preload" src={post.fimg_url} width="400" height="313" className='img-fluid img-hr' alt={post.title.rendered} />
                                        
                                    )}
                                    {
                                    ( !post.fimg_url && 
                                        <Card.Img variant="top" rel="preload" src='images/not-available.jpg' width="400" height="313" className='img-fluid img-hr' alt={post.title.rendered}/>
                                        
                                    )}
                                    
                                </div>
                                <Card.Body>
                                    <p className='date'>{post.date.substr(0,10)} / News</p>
                                    <Card.Text>
                                        {post.content.rendered.replace(regex, '').substr(0,120) + '...'}
                                    </Card.Text>
                                    <Card.Title>{post.title.rendered}</Card.Title>
                                </Card.Body>
                            </Card>
                            </a>
                             )}
                            {( !post.External_Link[0] &&
                        <a href='/' target="_blank" rel="noreferrer">
                            <Card style={{ width: '100%', padding: '15px'}}>
                                <div className='mdaImgBox'>
                                    {
                                    ( post.fimg_url && 
                                        <Card.Img variant="top" rel="preload" src={post.fimg_url} width="400" height="313" className='img-fluid img-hr' alt={post.title.rendered} />
                                        
                                    )}
                                    {
                                    ( !post.fimg_url && 
                                        <Card.Img variant="top" rel="preload" src='images/not-available.jpg' width="400" height="313" className='img-fluid img-hr' alt={post.title.rendered} />
                                        
                                    )}
                                    
                                </div>
                                <Card.Body>
                                    <p className='date'>{post.date.substr(0,10)} / News</p>
                                    <Card.Text>
                                        {post.content.rendered.replace(regex, '').substr(0,120) + '...'}
                                    </Card.Text>
                                    <Card.Title>{post.title.rendered}</Card.Title>
                                </Card.Body>
                            </Card>
                            </a>
                             )}
                        </div>
                    </Col>
                ))}
                </Row>
                <div className='ctm-pagination'>
                <ReactPaginate
                breakLabel="..."
                nextLabel={<span className='custom_next'><i class="bi-arrow-right"></i></span>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={0}
                pageCount={pageCount}
                previousLabel={<span className='custom_prev'><i class="bi-arrow-left"></i></span>}
                marginPagesDisplayed={0}
                renderOnZeroPageCount={null}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item'}
                activeLinkClassName={'active'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
            />
            </div> 
            </Container>
        </section>
    )
}

export default MediaTwo