import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


function Footer() {
  return (
    <footer className=''>
      <Container fluid className='p-l-r-50'>
        <div className='divider'>
            <hr />
        </div>
        <Row>
          <div className='flxdiv'>
            <div className='copyRt'>
              <p>Copyright © 2024 GrayCell Ventures. All Rights Reserved.</p>
            </div>
            <div className='ftrLink'>
              <div className='menuLink'>
                <a href="/#WhoWeAre">Who We Are</a>
                <a href="/#WhatWeOffer">What We Offer</a>
                <a href="/#Portfolio">Portfolio</a>
                <a href="/#Media">Media</a>
                <a href="/#Contact-Us">Contact Us</a>
              </div>
              <div className='ftrSclLink'>
               {/* <a href="https://in.linkedin.com/in/mjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-linkedin"></i></a>
                <a href="https://twitter.com/munishjauhar" target="_blank" rel="noreferrer" ><i class="bi bi-twitter"></i></a>*/}
              </div>
            </div>
          </div>
           
        </Row>
      </Container>
    </footer>
  )
}

export default Footer